<template>
  <div>
    <p class="mb-1">
      <strong>
        - {{name}} *
      </strong>
    </p>

    <div class="ml-5 mb-1">
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-written-text-'+id" v-model="contributeWrittenText" />
        <label class="form-check-label" :for="'contribute-written-text-'+id">Written Text</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-artwork-'+id" v-model="contributeArtwork" />
        <label class="form-check-label" :for="'contribute-artwork-'+id">Artwork</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-photographs-'+id" v-model="contributePhotographs" />
        <label class="form-check-label" :for="'contribute-photographs-'+id">Photographs</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-computer-program-'+id" v-model="contributeComputerProgram" />
        <label class="form-check-label" :for="'contribute-computer-program-'+id">Computer Program</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-translation-'+id" v-model="contributeTranslation" />
        <label class="form-check-label" :for="'contribute-translation-'+id">Translation</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-compilation-'+id" v-model="contributeCompilation" />
        <label class="form-check-label" :for="'contribute-compilation-'+id">Compilation</label>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" :id="'contribute-other-'+id" v-model="contributeOther" />
        <label class="form-check-label" :for="'contribute-other-'+id">Other</label>
      </div>
      <span class="error" v-show="!isSelectedOne">Please specify at least one.</span>
    </div>

    <base-input
      name="organization-name"
      label="If you checked 'Other', please specify:"
      rules="required"
      v-model="contributeOtherDetail"
      v-if="contributeOther"
    />
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  computed: {
    name() {
      return store.state.forms.FormCopyrightAuthor[this.id].name
    },
    isSelectedOne() {
      return this.contributeWrittenText | 
        this.contributeArtwork | 
        this.contributePhotographs | 
        this.contributeComputerProgram | 
        this.contributeTranslation | 
        this.contributeCompilation | 
        this.contributeOther
    },
    contributeWrittenText: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeWrittenText
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeWrittenText`, value})
      }
    },
    contributeArtwork: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeArtwork
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeArtwork`, value})
      }
    },
    contributePhotographs: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributePhotographs
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributePhotographs`, value})
      }
    },
    contributeComputerProgram: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeComputerProgram
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeComputerProgram`, value})
      }
    },
    contributeTranslation: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeTranslation
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeTranslation`, value})
      }
    },
    contributeCompilation: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeCompilation
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeCompilation`, value})
      }
    },
    contributeOther: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeOther
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeOther`, value})
      }
    },
    contributeOtherDetail: {
      get() {
        return store.state.forms.FormCopyrightAuthor[this.id].contributeOtherDetail
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].contributeOtherDetail`, value})
      }
    },
  }
}
</script>
