<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Author Contributions</h2>
    </div>
    <p class="mb-1">
      Indicate each element that you contributed to the work. If more than one author was identified in the prior step, then each needs to list their contribution. Please indicate the type of work contributed by the author. Check all that apply. (Later, you will need to submit a sample which shows each category of work claimed.)
    </p>
    <form>
      <copyright-author-contribution
        v-for="id in countRange"
        :key="id"
        :id="id"
      />
    </form>
  </div>
</template>

<script>
import range from 'lodash.range'
import store from '@/store'
import CopyrightAuthorContribution from './CopyrightAuthorContribution'

export default {
  components: {
    CopyrightAuthorContribution
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormCopyrightAuthor.length)
    },
    notSelectedAllContributions() {
      return store.state.forms.FormCopyrightAuthor.map(
        author => ( author.contributeWrittenText | 
          author.contributeArtwork | 
          author.contributePhotographs | 
          author.contributeComputerProgram | 
          author.contributeTranslation | 
          author.contributeCompilation | 
          author.contributeOther
        )
      )
      .filter(contribution => !contribution)
      .length > 0
    },
  }
}
</script>
