<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Authors Information</h2>
    </div>
    <form>
      <div class="mb-0">
        <strong>Who is the author of the work?</strong>
        <p class="mb-1">The "author" is copyright lingo for the person who created the work. If you did it as an individual, it would be you. If you did it as part of a work made for hire which means the work was specifically commissioned as such for an organization or it was done by an employee of a company, then the organization is considered the author.</p>
        <p class="text-caution mb-1">Note: If an organization is the author, made for hire MUST be selected.</p>
      </div>

      <!-- individual owners -->
      <div>
        <copyright-author
          v-for="id in countRange"
          :key="id"
          :id="id"
          @add="addAuthor"
          @delete="deleteAuthor"
        />
      </div>
    </form>
  </div>
</template>

<script>
import range from 'lodash.range'
import store from '@/store'
import CopyrightAuthor from './CopyrightAuthor'

export default {
  props: {},
  components: {
    CopyrightAuthor
  },
  methods: {
    addAuthor() {
      store.commit(
        'ADD_NEW_ITEM_IN_FORM_ARRAY',
        {
          type: 'FormCopyrightAuthor'
        }
      )
    },
    deleteAuthor(id) {
      store.commit('DELETE_ITEM_IN_FORM_ARRAY', {type: 'FormCopyrightAuthor', at: id})
    },
  },
  computed: {
    countRange() {
      return range(store.state.forms.FormCopyrightAuthor.length)
    },
  }
}
</script>