<template>
  <form-tabs>
    <form-tab tab-title="Contact Information">
      <form-contact-info />
    </form-tab>
    
    <form-tab tab-title="Copyright Information">
      <form-copyright-info />
    </form-tab>

    <form-tab icon="" tab-title="Publish Information">
      <form-copyright-publish-info />
    </form-tab>

    <form-tab icon="" tab-title="Authors Information">
      <form-copyright-author />
    </form-tab>

    <form-tab icon="" tab-title="Author Contributions">
      <form-copyright-contribution />
    </form-tab>

    <form-tab icon="" tab-title="Additional Information">
      <form-copyright-additional />
    </form-tab>
  </form-tabs>
</template>

<script>
import FormCopyrightInfo from '../../components/forms/FormCopyrightInfo.vue'
import FormCopyrightPublishInfo from '../../components/forms/FormCopyrightPublishInfo.vue'
import FormCopyrightAuthor from '../../components/forms/FormCopyrightAuthor.vue'
import FormCopyrightContribution from '../../components/forms/FormCopyrightContribution.vue'
import FormCopyrightAdditional from '../../components/forms/FormCopyrightAdditional.vue'

export default {
  components: {
    FormCopyrightInfo,
    FormCopyrightPublishInfo,
    FormCopyrightAuthor,
    FormCopyrightContribution,
    FormCopyrightAdditional
  },
  setup() {
    
  },
}
</script>
