<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Additional Information</h2>
    </div>
    <form>
      <select-yes-no-radio
        name="have-preexisted-material"
        v-model="haveMaterialPreexisted"
        rules="required"
        opt-class="mb-1"
      >
        Does the work have material not created by you that pre-existed before the creation of the work? *
      </select-yes-no-radio>

      <div v-if="haveMaterialPreexisted">
        <p class="mb-3">
          <strong>
            - Identify Pre-Existing Material *
          </strong>
        </p>

        <div class="ml-5 mb-3">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-written-text" v-model="existingWrittenText" />
            <label class="form-check-label" for="existing-written-text">Written Text</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-artwork" v-model="existingArtwork" />
            <label class="form-check-label" for="existing-artwork">Artwork</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-photographs" v-model="existingPhotographs" />
            <label class="form-check-label" for="existing-photographs">Photographs</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-computer-program" v-model="existingComputerProgram" />
            <label class="form-check-label" for="existing-computer-program">Computer Program</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-translation" v-model="existingTranslation" />
            <label class="form-check-label" for="existing-translation">Translation</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-compilation" v-model="existingCompilation" />
            <label class="form-check-label" for="existing-compilation">Compilation</label>
          </div>
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="existing-other" v-model="existingOther" />
            <label class="form-check-label" for="existing-other">Other</label>
          </div>
          <span class="error" v-show="!isSelectedOne">Please specify at least one.</span>
        </div>

        <base-input
          name="organization-name"
          label="If you checked 'Other', please specify:"
          rules="required"
          v-model="existingOtherDetail"
          v-if="existingOther"
        />
      </div>

      <select-yes-no-radio
        name="register-as-others"
        v-model="registerAsOthers"
        rules="required"
        opt-class="mb-1"
      >
        Is the copyright going to be registered in a name other than the author's?
      </select-yes-no-radio>

      <div class="mb-3" v-if="registerAsOthers">
        <label>Please state how the copyright was transferred from the author to the current copyright owner. *</label>

        <div class="select">
          <select class="form-control" v-model="transferType">
            <option value="">Select an option</option>
            <option value="written-assignment">Written Assignment</option>
            <option value="inheritance">Inheritance</option>
            <option value="other">Other</option>
          </select>
        </div>
      </div>

      <div v-if="registerAsOthers">
        <p class="my-1">
          Please enter the name this copyright should be registered under. If the claimant is an organization the contact person within the organization .
        </p>

        <base-input
          name="register-name"
          label="Name *"
          rules="required"
          v-model="registerName"
        />

        <base-input
          name="register-address"
          label="Address *"
          rules="required"
          v-model="registerAddress"
        />

        <base-input
          name="register-email"
          label="Email *"
          rules="required|email"
          v-model="registerEmail"
        />

        <base-input
          name="register-phone"
          label="Phone *"
          inputmask="(999)-999-9999"
          rules="required|tel-format"
          v-model="registerPhone"
        />
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Contact Information'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isSelectedOne() {
      return this.existingWrittenText | 
        this.existingArtwork | 
        this.existingPhotographs | 
        this.existingComputerProgram | 
        this.existingTranslation | 
        this.existingCompilation | 
        this.existingOther
    },
    haveMaterialPreexisted: {
      get() {
        return store.state.forms.FormCopyrightInfo.haveMaterialPreexisted
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.haveMaterialPreexisted', value})
      }
    },
    existingWrittenText: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingWrittenText
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingWrittenText', value})
      }
    },
    existingArtwork: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingArtwork
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingArtwork', value})
      }
    },
    existingPhotographs: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingPhotographs
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingPhotographs', value})
      }
    },
    existingComputerProgram: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingComputerProgram
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingComputerProgram', value})
      }
    },
    existingTranslation: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingTranslation
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingTranslation', value})
      }
    },
    existingCompilation: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingCompilation
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingCompilation', value})
      }
    },
    existingOther: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingOther
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingOther', value})
      }
    },
    existingOtherDetail: {
      get() {
        return store.state.forms.FormCopyrightInfo.existingOtherDetail
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.existingOtherDetail', value})
      }
    },

    registerAsOthers: {
      get() {
        return store.state.forms.FormCopyrightInfo.registerAsOthers
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.registerAsOthers', value})
      }
    },
    transferType: {
      get() {
        return store.state.forms.FormCopyrightInfo.transferType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.transferType', value})
      }
    },
    registerName: {
      get() {
        return store.state.forms.FormCopyrightInfo.registerName
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.registerName', value})
      }
    },
    registerAddress: {
      get() {
        return store.state.forms.FormCopyrightInfo.registerAddress
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.registerAddress', value})
      }
    },
    registerPhone: {
      get() {
        return store.state.forms.FormCopyrightInfo.registerPhone
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.registerPhone', value})
      }
    },
    registerEmail: {
      get() {
        return store.state.forms.FormCopyrightInfo.registerEmail
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.registerEmail', value})
      }
    },
  }
}
</script>
