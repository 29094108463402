<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Copyright Information</h2>
    </div>
    <form>
      <select-copyright-item name="work-type" opt-class="mb-4" rules="required" v-model="workType" />
      
      <!-- name details -->
      <base-input
        name="work-title"
        label="What is the title of the work you would like to copyright?"
        v-model="workTitle"
      >
        <template v-slot:description>
          <p class="text-example mb-1">
            Leave blank if there is no title. If you are registering a website, list the url (www.example.com) – Whatever you enter here will appear on your official Copyright Office Registration to identify the work. If this is a collection of works in a single application, such as a single album with several songs or a single collection of a number of essays, you must provide a title for the collection and you may provide titles to the individual parts, too, but you do not have to.
          </p>
        </template>
      </base-input>

      <label>What year did you complete your work? *</label>
      <select-year v-model="completeYear" rules="required" />
    </form>
  </div>
</template>

<script>
import SelectCopyrightItem from './common/SelectCopyrightItem'
import store from '@/store'

export default {
  components: {
    SelectCopyrightItem
  },
  props: {
    title: {
      type: String,
      default: 'Contact Information'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    workType: {
      get() {
        return store.state.forms.FormCopyrightInfo.workType
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.workType', value})
      }
    },
    workTitle: {
      get() {
        return store.state.forms.FormCopyrightInfo.workTitle
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.workTitle', value})
      }
    },
    completeYear: {
      get() {
        return store.state.forms.FormCopyrightInfo.completeYear
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.completeYear', value})
      }
    },
  }
}
</script>
