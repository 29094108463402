<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Publish Information</h2>
    </div>
    <p class="mb-3">
      Only one published work can be filed per application.<br/>
      You may register up to 10 unpublished works of the same kind using the new application for a “Group of Unpublished Works”. To submit more than 10 unpublished works, the US Copyright Office requires that you submit a separate application.
    </p>
    <form>
      <select-yes-no-radio name="has-published" v-model="hasPublished">
        Has the work been published? *
      </select-yes-no-radio>

      <!-- published option -->
      <div v-show="hasPublished">
        <label>Date of Publication *</label>
        <div class="d-flex mb-3">
          <select-month v-model="publishMonth" name="publish-month" opt-class="triple-picker"/>
          <select-date v-model="publishDate" name="publish-date" opt-class="triple-picker"/>
          <select-year v-model="publishYear" name="publish-year" opt-class="triple-picker"/>
        </div>

        <select-all-country v-model="publishedCountry" name="published-country" rules="required">
          Country of Publication *
        </select-all-country>

        <select-yes-no-radio name="has-published-electronically-only" v-model="hasEPublishedOnly">
          Has it only been published electronically *
        </select-yes-no-radio>
      </div>
      <!-- published option -->

      <select-yes-no-radio name="is-part-of-large" v-model="IsPartOfLargerPub">
        Is your work a part of a larger periodical (newspaper, magazine) or serial? *
      </select-yes-no-radio>

      <!-- part of larger publication option -->
      <div v-if="IsPartOfLargerPub">
        <base-input
          name="title-of-larger-publication"
          label="Title of Larger Publication *"
          v-model="titleOfLargerPub"
          rules="required"
        />

        <label>Date of Larger Publication *</label>
        <div class="d-flex mb-3">
          <select-month v-model="parentPublishMonth" name="parent-publish-month" opt-class="triple-picker"/>
          <select-date v-model="parentPublishDate" name="parent-publish-date" opt-class="triple-picker"/>
          <select-year v-model="parentPublishYear" name="parent-publish-year" opt-class="triple-picker"/>
        </div>

        <base-input
          name="volume-number-of-larger-publication"
          label="Volume # of Larger Publication *"
          type="text"
          v-model="volumeNumberOfLargerPub"
          rules="required"
        />
      </div>
      <!-- part of larger publication option -->
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  props: {
    title: {
      type: String,
      default: 'Contact Information'
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasPublished: {
      get() {
        return store.state.forms.FormCopyrightInfo.hasPublished
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.hasPublished', value})
      }
    },
    /**
     * If has been published already ?
     */
    publishYear: {
      get() {
        return `${new Date(store.state.forms.FormCopyrightInfo.publishDate).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormCopyrightInfo.publishDate)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.publishDate', value: fullDate})
      }
    },
    publishMonth: {
      get() {
        return `${new Date(store.state.forms.FormCopyrightInfo.publishDate).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormCopyrightInfo.publishDate)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.publishDate', value: fullDate})
      }
    },
    publishDate: {
      get() {
        return `${new Date(store.state.forms.FormCopyrightInfo.publishDate).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormCopyrightInfo.publishDate)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.publishDate', value: fullDate})
      }
    },
    publishedCountry: {
      get() {
        return store.state.forms.FormCopyrightInfo.publishedCountry
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.publishedCountry', value})
      }
    },
    hasEPublishedOnly: {
      get() {
        return store.state.forms.FormCopyrightInfo.hasEPublishedOnly
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.hasEPublishedOnly', value})
      }
    },
    /**
     * In case of Larger Publication
     */
    IsPartOfLargerPub: {
      get() {
        return store.state.forms.FormCopyrightInfo.IsPartOfLargerPub
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.IsPartOfLargerPub', value})
      }
    },
    titleOfLargerPub: {
      get() {
        return store.state.forms.FormCopyrightInfo.titleOfLargerPub
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.titleOfLargerPub', value})
      }
    },
    parentPublishYear: {
      get() {
        return `${new Date(store.state.forms.FormCopyrightInfo.dateOfLargerPub).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormCopyrightInfo.dateOfLargerPub)
        fullDate.setFullYear(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.dateOfLargerPub', value: fullDate})
      }
    },
    parentPublishMonth: {
      get() {
        return `${new Date(store.state.forms.FormCopyrightInfo.dateOfLargerPub).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormCopyrightInfo.dateOfLargerPub)
        fullDate.setMonth(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.dateOfLargerPub', value: fullDate})
      }
    },
    parentPublishDate: {
      get() {
        return `${new Date(store.state.forms.FormCopyrightInfo.dateOfLargerPub).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormCopyrightInfo.dateOfLargerPub)
        fullDate.setDate(parseInt(value))
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.dateOfLargerPub', value: fullDate})
      }
    },
    volumeNumberOfLargerPub: {
      get() {
        return store.state.forms.FormCopyrightInfo.volumeNumberOfLargerPub
      },
      set(value) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: 'FormCopyrightInfo.volumeNumberOfLargerPub', value})
      }
    },
  }
}
</script>
