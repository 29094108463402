<template>
  <div>
    <form>
      <div class="mb-1 border-bottom pb-0">
        <strong>Author #{{id+1}}</strong>
      </div>
      <div class="mb-1">
        <label :for="'name-'+id" class="form-label">Full Name or Organization Name*</label>
        <input 
          type="text" 
          :id="'name-'+id"
          name="name" 
          class="form-control" 
          :value="$store.state.forms.FormCopyrightAuthor[id].name"
          @input="handleNameChange"
        >
      </div>

      <div class="mb-1">
        <label :for="'mailing-address-'+id" class="form-label">Mailing Address *</label>
        <input
          type="text" 
          :id="'mailing-address-'+id"
          name="mailing-address"
          class="form-control"
          :value="$store.state.forms.FormCopyrightAuthor[id].mailingAddress"
          @input="handleAddressChange"
          required
        >
      </div>

      <div class="mb-1">
        <label :for="'city-'+id" class="form-label">City *</label>
        <input 
          type="text" 
          :id="'city-'+id" 
          name="city"
          class="form-control" 
          :value="$store.state.forms.FormCopyrightAuthor[id].city"
          @input="handleCityChange"
        >
      </div>

      <select-state 
        :name="'state-'+id"
        :value="$store.state.forms.FormCopyrightAuthor[id].state"
        @input="handleStateChange"
        rules="required"
      />

      <select-yes-no-radio
        :name="'made-for-hire-'+id"
        :value="$store.state.forms.FormCopyrightAuthor[id].madeForHire"
        @input="handleMadeForHireChange"
        opt-class="mb-1"
      >
        Was this author's work made for hire? *
      </select-yes-no-radio>

      <select-yes-no-radio
        :name="'made-anonym-'+id"
        :value="$store.state.forms.FormCopyrightAuthor[id].madeAnonymously"
        @input="handleMadeAnonymhange"
        opt-class="mb-1"
      >
        Author's work made anonymously? *
      </select-yes-no-radio>

      <select-yes-no-radio
        :name="'made-pseudonym-'+id"
        :value="$store.state.forms.FormCopyrightAuthor[id].createdPseudonym"
        @input="handleMadePseudonymChange"
        opt-class="mb-1"
      >
        Work created under a pseudonym? *
      </select-yes-no-radio>

      <div class="mb-1" v-if="$store.state.forms.FormCopyrightAuthor[id].createdPseudonym">
        <label :for="'pseudonym-name-'+id" class="form-label">Pseudonym Name *</label>
        <input
          type="text" 
          :id="'pseudonym-name-'+id"
          :name="'pseudonym-name-'+id"
          class="form-control"
          :value="$store.state.forms.FormCopyrightAuthor[id].pseudonymName"
          @input="handlePseudoNameChange"
          required
        >
      </div>
    </form>
  </div>
</template>

<script>
import store from '@/store'

export default {
  props: {
    id: {
      type: Number,
      default: 0
    },
  },
  methods: {
    handleDelete() {
      if (confirm(`Are you sure to delete this author?`)) {
        this.$emit('delete', this.id)
      }
    },
    handleAdd() {
      this.$emit('add')
    },
    handleNameChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].name`, value: e.target.value})
    },
    handleAddressChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].mailingAddress`, value: e.target.value})
    },
    handleCityChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].city`, value: e.target.value})
    },
    handleStateChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].state`, value: e})
    },
    handleMadeForHireChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].madeForHire`, value: e})
    },
    handleMadeAnonymhange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].madeAnonymously`, value: e})
      if (e === true) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].createdPseudonym`, value: false})  
      }
    },
    handleMadePseudonymChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].createdPseudonym`, value: e})
      if (e === true) {
        store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].madeAnonymously`, value: false})  
      }
    },
    handlePseudoNameChange(e) {
      store.commit('forms/SET_FORM_ATTRIBUTE', {key: `FormCopyrightAuthor[${this.id}].pseudonymName`, value: e.target.value})
    },
  }
}
</script>
