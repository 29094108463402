<template>
  <div :class="optClass">
    <label :for="generatedId" class="form-label">What you are wanting to copyright? *</label>
    <div class="select">
      <select class="form-control" :id="generatedId" @change="change" :value="value">
        <option value="">Select an option</option>
        <optgroup label="Literary Work">
          <option value="1">Written work such as fiction, nonfiction, poetry, textbooks, reference works or articles</option>
          <option value="2">Directories or catalogs, advertising copy</option>
          <option value="3">Computer programs</option>
          <option value="4">Website or online materials</option>
        </optgroup>
        <optgroup label="Work of Visual Arts">
          <option value="5">Photograph</option>
          <option value="6">Art Work</option>
          <option value="7">Maps</option>
          <option value="8">Technical Drawings</option>
        </optgroup>
        <optgroup label="Sound Recording">
          <option value="9">Recorded performance of music or sound</option>
        </optgroup>
        <optgroup label="Work of Performing Art">
          <option value="10">Written music &amp; Lyrics</option>
          <option value="11">Screenplay or script</option>
          <option value="12">Choreographic work</option>
          <option value="13">A recorded score for a movie or play</option>
        </optgroup>
        <optgroup label="Motion Picture/AV Work">
          <option value="14">Feature film, documentary film, animated film, television show, video, or other Audio-Visual Work</option>
          <option value="15">Video Game</option>
        </optgroup>
      </select>
    </div>
  </div>
</template>

<script>
import BaseSelect from './BaseSelect'

export default {
  mixins: [BaseSelect],
  props: {
    name: {
      type: String,
      default: 'state'
    },
    optClass: {
      type: String,
      default: 'mb-3'
    },
    value: {
      type: String
    },
    rules: {
      type: String,
      default: ''
    }
  },
}
</script>
